<template>
  <div class="wytg">
    <leftDetail
      v-if="showDetail"
      @closeDialog1="closeDialog"
      ref="showDetail"
    />
    <rightDetail
      v-if="showDetail1"
      @closeDialog2="closeDialog"
      ref="showDetail1"
    />
    <showDetail
      v-if="showDetail2"
      @closeDialog2="closeDialog"
      ref="showDetail2"
    />
    <showDetail
      v-if="showDetail3"
      @closeDialog2="closeDialog"
      ref="showDetail3"
    />
    <examineBzj
      :visible="bzjVisible"
      @close="closeDialog"
      :examineData="examineData"
    ></examineBzj>
    <div class="form-list">
      <div class="table-search">
        <h3>站内信息</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pageNum"
          :pageSize="query.pageSize"
          :total="total"
          :border="true"
          :loading="loading"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="index" slot-scope="scope">
            <span>{{
              query.pageSize * (query.pageNum - 1) + scope.index + 1
            }}</span>
          </template>
          <template slot="readFlag" slot-scope="scope">
            <span>{{ scope.row.readFlag ? "已查阅" : "未查阅" }}</span>
          </template>
          <template slot="sendTime" slot-scope="scope">
            <span>{{ scope.row.sendTime ? scope.row.sendTime : "-" }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailClick(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
import leftDetail from "../../../homepage/components/SupplyTgLeftDetail.vue";
import rightDetail from "../../../homepage/components/SupplyTgRightDetail.vue";
import showDetail from "../../tggl/wytg/showDetail.vue";
import showDetail1 from "../../tggl/wyjg/showDetail.vue";
import examineBzj from "../../zyzcgl/bzjsh/examineBzj.vue";
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import {
  getStaionPagelist,
  updateReadFlag,
  getDetail2,
  getCompanyById1,
} from "@/api/add";
import { getBzjCheckResult } from "@/api/exchange";
import { znxxColumn } from "../tableColumn";
const Query = [
  {
    name: "content",
    label: "文章标题：",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "160px",
  },
];
export default {
  components: {
    leftDetail,
    rightDetail,
    showDetail,
    showDetail1,
    examineBzj,
    FormQuery,
    TablePage,
  },
  data() {
    return {
      loading: false,
      level1Type: [],
      level2Type: [],
      showEdit: false,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      Query: Query,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      columns: znxxColumn,
      tableData: [],
      showDetail: false,
      showDetail1: false,
      showDetail2: false,
      showDetail3: false,
      bzjVisible: false,
      examineData: null,
    };
  },
  watch: {
    keyForm(n, o) {
      console.log("keyForm", n);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    handleSelectChange() {
      this.query.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.query.pageNum = page;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.query.pageNum = 1;
      this.query.pageSize = pageSize;
      this.getList();
    },
    editClick() {
      this.$message.info("编辑");
    },

    delClick(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // let res = await deleteRole(id);
          // console.log("删除返回", res);
          // if (res.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getList();
          // }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    async getList() {
      this.loading = true;

      let res = await getStaionPagelist(this.query);
      if (!res.code) {
        this.tableData = res.data.records;
        this.total = parseInt(res.data.total);
        this.loading = false;
      } else {
        this.$message.error("查询失败");
        this.loading = false;
      }
    },
    search(params) {
      this.query.pageNum = 1;
      this.query.pageSize = 10;
      this.query = { ...this.query, ...params };
      console.log(this.query, "查看");
      this.getList();
    },
    submit() {},
    async detailClick(index, row) {
      console.log("查看", row);
      sessionStorage.setItem("fromZnxx", true);
      let res = await updateReadFlag({ id: row.id });
      if (res.data) {
        this.getList();
      }
      let type = row.type;
      switch (type) {
        case "ZYLZ-TS":
          this.$router.push({
            name: "projectDetail",
            query: { c: row.projectCode },
          });
          break;
        case "ZYLZ-BZJSHTG":
          this.$router.push({ name: "ybmxm", query: { c: row.projectCode } });
          break;
        case "ZYLZ-BZJSHBTG":
          this.$router.push({ name: "ybmxm", query: { c: row.projectCode } });
          break;
        case "ZYLZ-BZJSH":
          let resBzj = await getBzjCheckResult({ bzjId: row.projectCode });
          if (resBzj.status == 200 && resBzj.rel) {
            this.examineData = resBzj.data;
            this.examineData.bzjUrl =
              window.globalUrl.HOME_API +
              "admin/file/get?ossFilePath=" +
              this.examineData.bzjPz;
            this.bzjVisible = true;
          } else {
            this.$message.error(resBzj.message);
            this.$router.push({ name: "bzjsh", query: { c: row.projectCode } });
          }
          break;
        case "ZYLZ-ZBJGTG":
          this.$router.push({ name: "jjjgck", query: { c: row.projectCode } });
          break;
        case "ZYLZ-ZBJGBTG":
          this.$router.push({ name: "jjjgck", query: { c: row.projectCode } });
          break;
        case "TDTG-NHTS":
          let res1 = await getDetail2({ id: row.projectCode });
          if (res1.code == 0 && res1.data) {
            this.showDetail1 = true;
            this.$nextTick(() => {
              this.$refs.showDetail1.init(res1.data, "需求信息详情");
            });
          }
          break;
        case "TDTG-JTTSNFS":
          let res2 = await getCompanyById1({ id: row.projectCode });
          if (res2.code == 0 && res2.data) {
            this.showDetail = true;
            this.$nextTick(() => {
              this.$refs.showDetail.init(res2.data);
            });
          }
          break;
        case "TDTG-NHDHT":
        case "TDTG-NFSDHT":
          let res3 = await getDetail2({ id: row.projectCode });
          if (res3.code == 0 && res3.data) {
            this.showDetail2 = true;
            this.$nextTick(() => {
              this.$refs.showDetail2.init(res3.data);
            });
          }
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.showDetail = false;
      this.showDetail1 = false;
      this.showDetail2 = false;
      this.showDetail3 = false;
      this.bzjVisible = false;
    },
    reset() {
      this.query = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
  }
  .table-content {
    padding: 0 30px;
  }
}
</style>
